<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1589_1226)">
      <path
        d="M6.5 11.875C9.19239 11.875 11.375 9.69239 11.375 7C11.375 4.30761 9.19239 2.125 6.5 2.125C3.80761 2.125 1.625 4.30761 1.625 7C1.625 9.69239 3.80761 11.875 6.5 11.875Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5 4.15625V7H9.34375"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1589_1226">
        <rect
          width="13"
          height="13"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
