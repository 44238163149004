<template>
  <div id="uxAnnotationMenu">
    <div class="menu">
      <div
        class="transcription"
        @click="selectOption('transcription')"
        :class="{ isSelected: selectedOption == 'transcription' }"
      >
        TRANSCRIPCIÓN
      </div>
      <div
        class="notes"
        @click="selectOption('notes')"
        :class="{ isSelected: selectedOption == 'notes' }"
      >
        NOTAS
      </div>
      <div
        class="userInfo"
        @click="selectOption('userInfo')"
        :class="{ isSelected: selectedOption == 'userInfo' }"
      >
        INFO USUARIO
      </div>
      <div
        class="selectionDiv"
        :class="{
          option1: selectedOption == 'transcription',
          option2: selectedOption == 'notes',
          option3: selectedOption == 'userInfo',
        }"
      ></div>
    </div>
  </div>
</template>
<script setup>
const emit = defineEmits(["changeOption"]);
const props = defineProps({
  selectedOption: {
    type: String,
  },
});
const selectOption = (option) => {
  emit("changeOption", option);
};
</script>
<style lang="scss" scoped>
#uxAnnotationMenu {
  width: 100%;
  height: fit-content;
  background-color: white;
  border-radius: 4px;
  padding: 5px;
  .menu {
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: row;
    > div:not(.selectionDiv) {
      padding: 8px 0px;
      height: fit-content;
      width: 100%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      text-align: center;
      background-color: transparent;
      color: black;
      transition: color 0.5s;
      cursor: pointer;
      &.isSelected {
        color: white;
      }
    }
    .selectionDiv {
      position: absolute;
      width: calc(100% / 3);
      height: 100%;
      z-index: 0;
      top: 0;
      //background-color: #8134ff;
      background: linear-gradient(90deg, #8134ff 0%, #d100a3 100%);
      border-radius: 3px;
      transition: left 0.5s cubic-bezier(0.42, 0.69, 0.61, 1.18);
      &.option1 {
        left: 0;
      }
      &.option2 {
        left: calc(100% / 3);
        //background-color: #fd7328;
        background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
      }
      &.option3 {
        left: calc((100% / 3) * 2);
        background: linear-gradient(90deg, #96c800 0%, #96c800 100%);
      }
    }
  }
}
</style>
