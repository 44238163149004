<template>
  <div id="uxNoteList">
    <template v-for="(note, index) in notes">
      <UxNote
        :id="'note' + index"
        :index="index"
        :note="note.note"
        :second="note.second"
        :isCurrentNote="
          currentTime >= note.second &&
          (index + 1 == notes.length || currentTime < notes[index + 1].second)
        "
        @goToSecond="goToSecond"
        @scrollToNote="scrollToNote"
      />
    </template>
  </div>
</template>
<script setup>
import UxNote from "./UxNote.vue";
const props = defineProps(["currentTime", "notes"]);
const emit = defineEmits(["goToSecond"]);
const goToSecond = (second) => {
  emit("goToSecond", second);
};
const scrollToNote = (id) => {
  document
    .getElementById(id)
    .scrollIntoView({ behavior: "smooth", block: "center" });
};
</script>
<style lang="scss" scoped>
#uxNoteList {
  max-height: 50vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 5px;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #626262;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
  }
}
</style>
