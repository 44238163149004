<template>
  <div id="uxNotes">
    <h1>Listado de notas</h1>
    <h2>
      Hay un total de <span>{{ notes.length }}</span> notas
    </h2>
    <UxAnnotationSearchBar placeHolder="Busca notas por mensaje" />
    <UxNoteList
      :notes="notes"
      :currentTime="currentTime"
      @goToSecond="goToSecond"
    />
  </div>
</template>
<script setup>
import UxAnnotationSearchBar from "./UxAnnotationSearchBar.vue";
import UxNoteList from "./UxNoteList.vue";

const props = defineProps(["currentTime", "notes"]);
const emit = defineEmits(["goToSecond"]);
const goToSecond = (second) => {
  emit("goToSecond", second);
};
</script>
<style lang="scss" scoped>
#uxNotes {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  h1 {
    font-family: "Bariol";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #1f1f1f;
  }
  h2 {
    font-family: "Bariol";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #1f1f1f;
    > span {
      background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}
</style>
