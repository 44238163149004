const MockUp = {
  alerts: [
    {
      id            : 3076,
      type          : "EL_COMMUNITY_LAUNCHED",
      seen          : false,
      text          : { translated:"Ya tienes disponible tu nueva comunidad Coca cola ¡Adelante!" },
      visualization : "ALERT",
      creationDate  : "2019-01-30T11:23:00.604+0000",
      communityId   : 104,
      activityId    : 214
    },
    {
      id            : 3072,
      type          : "EL_NEW_ANSWER_TO_COMMENT",
      seen          : false,
      text          : { translated:"¡Tienes una respuesta nueva a tu comentario en la actividad Experience lab!" },
      visualization : "ALERT",
      creationDate  : "2019-01-30T09:46:02.239+0000",
      communityId   : 104,
      activityId    : 200
    },
    {
      id            : 3070,
      type          : "EL_NEW_MEDAL",
      seen          : false,
      text          : { translated:"Has recibido una medalla en la actividad Actividad prueba ¡enhorabuena!" },
      visualization : "ALERT",
      creationDate  : "2019-01-30T08:18:45.600+0000",
      communityId   : 104,
      activityId    : 191,
      commentId     : 464
    },
    {
      id            : 3047,
      type          : "EL_COMMUNITY_ACTIVIY_LAUNCHED",
      seen          : true,
      text          : { translated:"Ya tienes disponible una una nueva actividad en la comunidad Coca cola ¡Adelante!" },
      visualization : "ALERT",
      creationDate  : "2019-01-29T15:25:00.540+0000",
      communityId   : 104,
      activityId    : 205
    },
    {
      id            : 2651,
      type          : "EL_COMMUNITY_ACTIVIY_REMINDER",
      seen          : false,
      text          : { translated:"¡Toc, toc! Tienes una actividad pendiente de realizar (Actividad con testers) en la comunidad Coca cola" },
      visualization : "ALERT",
      creationDate  : "2019-01-24T17:11:02.759+0000",
      communityId   : 104,
      activityId    : 196
    },
    {
      id            : 2666,
      type          : "EL_NEW_MEDAL",
      seen          : false,
      text          : { translated:"¡Una medallita que has ganado!" },
      visualization : "ALERT",
      creationDate  : "2019-01-21T18:10:00.759+0000",
      communityId   : 104,
      activityId    : 196
    },
    {
      id            : 3000,
      type          : "EL_NEW_ANSWER_TO_COMMENT",
      seen          : true,
      text          : { translated:"¡Hay una respuesta esperándote en Experience lab!" },
      visualization : "ALERT",
      creationDate  : "2019-01-15T09:38:05.000+0000",
      communityId   : 104,
      activityId    : 300
    },
  ],
  unseenAlerts: 5
}

export default MockUp;