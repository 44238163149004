<template>
  <div id="uxUserInfo">
    <h1>Información sobre el usuario</h1>
    <div class="content">
      <div>
        <h2>Datos básicos del usuario</h2>
        <template v-for="(item, key, index) in basicInfo" :key="key">
          <div class="data">
            <p>{{ translateKey(key) }}</p>
            <div class="separator"></div>
            <p>{{ item }}</p>
          </div>
        </template>
      </div>
      <div>
        <h2>Datos técnicos</h2>
        <template v-for="(item, key, index) in technicalInfo" :key="key">
          <div class="data">
            <p>{{ translateKey(key) }}</p>
            <div class="separator"></div>
            <p>{{ item }}</p>
          </div>
        </template>
      </div>
      <!-- <div>
        <h2>Datos de uso y experiencia</h2>
        <div class="data">
          <p>Dsipositivo utilizado</p>
          <div class="separator"></div>
          <p>Desktop</p>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script setup>
import { ref, onBeforeMount } from "vue";
const props = defineProps({
  sex: {
    type: String,
    default: ".",
  },
  age: {
    type: String,
    default: ".",
  },
  location: {
    type: String,
    default: ".",
  },
  education: {
    type: String,
    default: ".",
  },
  income: {
    type: String,
    default: ".",
  },
  occupation: {
    type: String,
    default: ".",
  },
  maritalStatus: {
    type: String,
    default: ".",
  },
  device: {
    type: String,
    default: "",
    validator(value) {
      return ["Desktop", "Mobile", "Tablet"].includes(value);
    },
  },
  browser: {
    type: String,
    default: "Chrome",
  },
  connectionSpeed: {
    type: String,
    default: ".",
  },
});
const basicInfo = ref({});
const technicalInfo = ref({});
const setBasicInfo = () => {
  for (const prop in props) {
    const value = props[prop];
    if (
      value !== "" &&
      prop !== "device" &&
      prop !== "browser" &&
      prop !== "connectionSpeed"
    ) {
      basicInfo.value[prop] = value;
    }
  }
};
const setTechnicalInfo = () => {
  for (const prop in props) {
    const value = props[prop];
    if (
      value !== "" &&
      (prop == "device" || prop == "browser" || prop == "connectionSpeed")
    ) {
      technicalInfo.value[prop] = value;
    }
  }
};
const translateKey = (key) => {
  switch (key) {
    case "sex":
      return "Sexo";
    case "age":
      return "Edad";
    case "location":
      return "Provincia/CCA/Zona geográfica";
    case "education":
      return "Nivel de educación";
    case "income":
      return "Ingresos anuales";
    case "occupation":
      return "Ocupación";
    case "maritalStatus":
      return "Estado civil";
    case "device":
      return "Dispositivo utilizado";
    case "browser":
      return "Navegador utilizado";
    case "connectionSpeed":
      return "Velocidad de conexión";
    default:
      "propiedad";
      break;
  }
};
onBeforeMount(() => {
  setBasicInfo();
  setTechnicalInfo();
});
</script>
<style lang="scss" scoped>
#uxUserInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  h1 {
    margin-top: 20px;
    font-family: "Bariol";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #1f1f1f;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-height: 65vh;
    overflow-y: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #626262;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      //background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
      background-color: #96C800;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #96C800;
    }
    > div {
      display: flex;
      flex-direction: column;
      gap: 13px;
      margin-right: 15px;
      h2 {
        font-family: "Bariol";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #96C800;
      }
      .data {
        display: flex;
        flex-direction: row;
        gap: 15px;
        p {
          width: fit-content;
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          &:last-child {
            font-weight: 700;
          }
        }
        > div {
          height: 100%;
          flex-grow: 1;
          align-self: flex-end;
          border-bottom: 1px dashed #c8c8c8;
        }
      }
    }
  }
}
</style>
