<template>
  <div class="uxNote" :class="{ isCurrentNote: isCurrentNote }">
    <div class="icon">
      <IconBookMark />
    </div>
    <div class="content" @click="goToSecond">
      <div class="note">
        {{ note }}
      </div>
      <div class="time">
        {{ time }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onBeforeMount } from "vue";
import IconBookMark from "../icons/IconBookMark.vue";

const props = defineProps({
  note: {
    type: String,
    default: "klk",
  },
  second: {
    type: Number,
    default: 3.481589,
  },
  isCurrentNote: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
  },
});
const emit = defineEmits(["goToSecond", "scrollToNote"]);

watch(
  () => props.isCurrentNote,
  (isCurrentNote) => {
    if (isCurrentNote) {
      emit("scrollToNote", `note${props.index}`);
    }
  }
);
const time = ref("");
onBeforeMount(() => {
  time.value = createTime(props.second);
});
const createTime = (duration) => {
  const time = new Date(duration * 1000);
  let hours = (time.getHours() - 1).toString();
  let minutes = time.getMinutes().toString();
  let seconds = time.getSeconds().toString();
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;
  return `${hours}:${minutes}:${seconds}`;
};
const goToSecond = () => {
  emit("goToSecond", props.second);
};
</script>
<style lang="scss" scoped>
.uxNote {
  display: flex;
  flex-direction: row;
  gap: 7px;
  background-color: white;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  border-width: 0px;
  border-style: solid;
  border-color: white;
  transition: border-color 1.5s;
  &.isCurrentNote {
    border-width: 2px;
    border-color: #ff1564;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .note {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #626262;
    }
    .time {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}
</style>
