<template>
  <div id="uxTranscripting">
    <div class="center">
      <IconTranscripting />
      <h1 v-if="activityType == 'WAT_UX' " >Estamos transcribiendo el video Ux</h1>
      <h1 v-else >Estamos transcribiendo la videollamada</h1>
      <p>
        Este proceso puedo llevar alrededor de 1-2 horas, por lo que te pedimos
        un poco de paciencia :)
      </p>
    </div>
  </div>
</template>
<script setup>
import IconTranscripting from "../icons/IconTranscripting.vue";
 const props = defineProps({
   activityType:String
 })
</script>
<style lang="scss" scoped>
#uxTranscripting {
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .center {
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 30px;
    svg {
      margin-left: auto;
      margin-right: auto;
    }
    h1 {
      font-family: "Bariol";
      font-style: normal;
      font-weight: 700;
      font-size: 33px;
      text-align: center;
      color: #8134ff;
    }
    p {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: #626262;
    }
  }
}
</style>
