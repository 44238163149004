<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.38999 0.414699C0.639775 0.164989 0.978512 0.024709 1.33171 0.024709C1.68491 0.024709 2.02364 0.164989 2.27343 0.414699L7.99167 6.13294L13.7099 0.414699C13.8328 0.28748 13.9798 0.186005 14.1423 0.116197C14.3048 0.0463885 14.4796 0.00964381 14.6564 0.00810693C14.8333 0.00657006 15.0087 0.0402715 15.1724 0.107245C15.3361 0.174219 15.4848 0.273124 15.6099 0.398188C15.7349 0.523252 15.8338 0.671972 15.9008 0.835669C15.9678 0.999366 16.0015 1.17476 15.9999 1.35162C15.9984 1.52849 15.9617 1.70327 15.8919 1.86578C15.8221 2.02829 15.7206 2.17526 15.5934 2.29814L9.87511 8.01638L15.5934 13.7346C15.836 13.9858 15.9702 14.3223 15.9672 14.6716C15.9642 15.0208 15.8241 15.3549 15.5771 15.6018C15.3302 15.8488 14.9961 15.9889 14.6468 15.9919C14.2976 15.995 13.9611 15.8607 13.7099 15.6181L7.99167 9.89982L2.27343 15.6181C2.02221 15.8607 1.68575 15.995 1.3365 15.9919C0.987258 15.9889 0.653177 15.8488 0.406215 15.6018C0.159253 15.3549 0.0191681 15.0208 0.0161333 14.6716C0.0130984 14.3223 0.147356 13.9858 0.38999 13.7346L6.10824 8.01638L0.38999 2.29814C0.14028 2.04835 0 1.70961 0 1.35642C0 1.00322 0.14028 0.664484 0.38999 0.414699Z"
      :fill="fill"
    />
  </svg>
</template>
<script setup>
const props = defineProps({
  width: {
    type: Number,
    default: 16
  },
  height: {
    type: Number,
    default: 16
  },
  fill: {
    type: String,
    default: "black"
  },
});
</script>
