// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/waves-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".AnalysisThemes[data-v-7eacee6a] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), var(--primary-color);\n  border-radius: 20px;\n  padding: 40px 0 40px 40px;\n  display: grid;\n  grid-template-columns: 40% 60%;\n  height: 340px;\n  align-items: flex-start;\n  gap: 60px;\n  overflow: hidden;\n}\n.AnalysisThemes--Left[data-v-7eacee6a] {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-direction: column;\n}\n.AnalysisThemes--Right[data-v-7eacee6a] {\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  gap: 10px;\n  overflow: hidden;\n  max-height: 265px;\n}\n.AnalysisThemes--Right span[data-v-7eacee6a] {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: white;\n  font-family: \"Lato\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 15px;\n  color: #626262;\n  height: 44px;\n  border-radius: 20px;\n  padding: 0 15px;\n}\nh1[data-v-7eacee6a] {\n  font-family: \"Bariol\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 40px;\n  color: white;\n  margin: 0;\n}\np[data-v-7eacee6a] {\n  font-family: \"Lato\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  color: white;\n  opacity: 0.7;\n  margin: 12px 0 32px;\n}\nbutton[data-v-7eacee6a] {\n  padding: 10px 16px;\n  height: 37px;\n  background: var(--accent-color);\n  border-radius: 4px;\n  color: white;\n  font-family: \"Lato\";\n  font-weight: 900;\n  font-size: 14px;\n  text-transform: uppercase;\n  border: none;\n  cursor: pointer;\n}\n@media screen and (max-width: 1080px) {\n.AnalysisThemes[data-v-7eacee6a] {\n    padding: 20px 0 20px 20px;\n    gap: 30px;\n    grid-template-columns: 50% 50%;\n}\n}", ""]);
// Exports
module.exports = exports;
